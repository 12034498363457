const linkConfig = {
  web: window.location.origin.replace("acat", "www"),
  h5: window.location.origin.replace("acat", "h5"),
  ios: "https://apps.apple.com/app/apple-store/id1583183039",
  android: [
    "https://downloads.atmzhwmw.com/source/material/anto_tuijian.apk",
    "https://mp-18b59665-8ace-4294-a961-e5d5164f6bef.cdn.bspapp.com/cloudstorage/23.07/anto_tuijian_v187_0703.apk",
  ],
  android_backup: "https://mp-18b59665-8ace-4294-a961-e5d5164f6bef.cdn.bspapp.com/cloudstorage/23.07/anto_tuijian_v187_0703.apk",
  online: "https://shunlong.s5.udesk.cn/im_client/?web_plugin_id=1379&group_id=1046",
  login: "https://fo.aattuser.com/Frontoffice",
  disclaimer:
    window.location.origin.indexOf("antohq") > 0
      ? window.location.origin.replace("acat", "www") + "/singlepage/disclaimer"
      : window.location.origin.replace("acat", "www") +
      "/singlepage?type=disclaimer",
  risk:
    window.location.origin.indexOf("antohq") > 0
      ? window.location.origin.replace("acat", "www") +
      "/singlepage/risk_disclosure"
      : window.location.origin.replace("acat", "www") +
      "/singlepage?type=risk_disclosure",
  agreement: window.location.origin.indexOf("antohq") > 0
    ? window.location.origin.replace("acat", "www") +
    "/singlepage/client_agreement"
    : window.location.origin.replace("acat", "www") +
    "/singlepage?type=client_agreement",
  policy: window.location.origin.indexOf("antohq") > 0
    ? window.location.origin.replace("acat", "www") +
    "/singlepage/privacy_policy"
    : window.location.origin.replace("acat", "www") +
    "/singlepage?type=privacy_policy",
};

const sourceUrl = "_s_u_l_";

export { linkConfig, sourceUrl };
