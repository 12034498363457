import React from "react";
import styles from "./styles.module.scss";
import { linkConfig } from "../../config";
export default () => {
  return (
    <footer className={`${styles.footer} text-center`}>
      <p>
        <a href={linkConfig.risk} target="_blank">
          风险披露
        </a>
        <a href={linkConfig.disclaimer} target="_blank">
          免责声明
        </a>
      </p>
      <p>
        如有任何疑问，欢迎随时联系在线客服，或致电24小时客服热线400-842-8959（中国免费热线）
      </p>
      <p>
        Copyright © {new Date().getFullYear()} 安东环球（Anto
        Global）版权所有，保留所有权利，不得转载
      </p>
    </footer>
  );
};
