import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik } from "formik";
import { useParams, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { parse } from "qs";
import { updateAccount, getAuthUrl } from "../services";
import Utils, { getAgeByIDCard } from "../utils";
import Layout from "../components/Layout";
import styles from "./styles.module.scss";
const { downloadNow } = Utils;
export default () => {
  const formRef = useRef(null);
  const history = useHistory();
  // 追踪参数
  const { updateCustomerUuid } = useParams();
  //状态
  const [isSubmiting, setSubmiting] = useState(false);
  const [idValue, setIdValue] = useState('');
  const [isOK, setIsOK] = useState(false);

  const [state, setState] = useState({
    url: "",
    visible: false,
  });

  const location = useLocation();
  const params = parse(location.search.slice(1));

  const isApp = () => {
    if (params.from instanceof Array) {
      return params.from.includes("app");
    } else {
      return params.from === "app";
    }
  };

  const clientWidth = document.body.clientWidth || 1080;
  const isPc = clientWidth > 1080;

  const isApp_key = isApp() || isPc;

  const [tips, setTips] = useState({
    type: null,
    msg: null,
  });

  const setMessage = useCallback(({ type, msg, time = 5 }) => {
    setTips({
      type,
      msg,
    });
    const timer = setTimeout(() => {
      setTips({ type: null, msg: null });
      clearTimeout(timer);
    }, time * 1000);
  }, []);

  const updateCustomer = useCallback(
    async (values) => {
      if (!isOK) return;
      try {
        if (
          values.idDocumentNumber &&
          getAgeByIDCard(values.idDocumentNumber) < 18
        ) {
          setMessage({
            type: "error",
            msg: "差价合约属高风险投资，不适合未成年人参与",
          });
          return;
        }
        setSubmiting(true);
        const res = await updateAccount({
          ...values,
          updateCustomerUuid,
        });
        setSubmiting(false);
        if (res.code !== "OK") {
          setMessage({
            type: "error",
            msg: res.msg,
          });

          return;
        }
        if (res.code === "OK" && res.data && !res.data.isInAudit) {
          history.replace("/audit");
          return;
        }
        if (window.sessionStorage) {
          const sessionStorage = window.sessionStorage;
          const phone = sessionStorage.getItem("__r__");
          const pwd = sessionStorage.getItem("__p__");
          const signature = phone + "#" + pwd + "#3#0";
          if (window.isNative) {
            window.isNative.willLoginWith &&
              window.isNative.willLoginWith(signature);
            return;
          }
          if (window.webkit && window.webkit.messageHandlers.willLoginWith) {
            window.webkit.messageHandlers.willLoginWith.postMessage({
              body: signature,
            });
            return;
          }
          if (window.willLoginWith) {
            window.willLoginWith(signature);
            return;
          }
          // 监听安卓物理返回键
          if (window.isNative && window.isNative.getUserAccount) {
            window.isNative.getUserAccount(signature);
            return;
          }
        }

        if (state.url) {
          setState((t) => ({
            ...t,
            visible: true,
          }));
          return;
        }
        const authUrl = await getAuthUrl(updateCustomerUuid);
        if (authUrl.code !== "OK") {
          setMessage({
            type: "error",
            msg: authUrl.msg,
          });

          return;
        }

        setState((t) => ({
          ...t,
          url: authUrl.data,
          visible: true,
        }));
      } catch (error) { }
    },
    [updateCustomerUuid, state.url, isOK]
  );

  useEffect(() => {
    (async () => {
      const authUrl = await getAuthUrl(updateCustomerUuid);
      if (authUrl.code === "OK") {
        const url = authUrl.data;
        setState((t) => ({
          ...t,
          url,
        }));
      }
    })();
  }, [updateCustomerUuid]);

  const handleBtn = () => {
    if (isApp_key) {
      window.location.href = state.url;
    } else {
      downloadNow();
    }
  };


  //获取年龄
  const calculateAge = (idCard) => { // birthday is a date
    var myDate = new Date();
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var age = myDate.getFullYear() - idCard.substring(6, 10) - 1;
    if (idCard.substring(10, 12) < month || idCard.substring(10, 12) == month && idCard.substring(12, 14) <= day) {
      age++;
    }
    return age;
  }

  useEffect(() => {
    const isOK = calculateAge(idValue) >= 18;
    setIsOK(isOK);
  }, [idValue]);

  const isShowSkip = () => {
    if (params.utm_source && !isApp()) {
      return false;
    }
    return true;
  };

  return (
    <Layout
      className="flex-container layout-vertical"
      title="完善资料可领取50元红包，确定放弃吗?"
      again_title="开户成功"
      cancel="确认跳过"
      redirect={state.url.replace(
        "https://fo.atbgovernor.com/Frontoffice/fundDepositOnline.do",
        "https://fo.atbgovernor.com/Frontoffice/index.do"
      )}
      skip={isShowSkip()}
      step={2}
    >
      <div className={styles.wrapper}>
        <div className="form flex-block">
          <Formik
            innerRef={formRef}
            initialValues={{
              chineseName: "",
              idDocumentNumber: "",
              email: "",
            }}
            validationSchema={yup.object().shape({
              chineseName: yup
                .string()
                .required("请输入姓名")
                .matches(Utils.validate.chineseName, "请输入正确姓名"),
              idDocumentNumber: yup
                .string()
                .required("请输入身份证号码")
                .matches(
                  Utils.validate.idDocumentNumber,
                  "请输入正确的身份证号码"
                ),
              email: yup
                .string()
                .required("请输入电子邮箱")
                .matches(Utils.validate.email, "请输入正确的电子邮箱"),
            })}
            onSubmit={(values) => {
              updateCustomer(values);
            }}
          >
            {({
              handleSubmit,
              touched,
              values,
              errors,
              setFieldValue,
              isValid,
              dirty,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <h2 className="page-title">
                  完善资料
                  <p className="page-title-desp">
                    信息安全加密，仅用于实名认证。
                  </p>
                </h2>
                <div
                  className={`${(values.chineseName || touched.chineseName) &&
                    errors.chineseName
                    ? "err"
                    : ""
                    } item`}
                >
                  <div className={`form-item`}>
                    <input
                      type="text"
                      placeholder="请输入姓名"
                      maxLength={11}
                      onChange={(e) => {
                        setFieldValue("chineseName", e.target.value);
                      }}
                    />
                  </div>
                  {(values.chineseName || touched.chineseName) &&
                    errors.chineseName && (
                      <div className="error">{errors.chineseName}</div>
                    )}
                </div>
                <div
                  className={`${(values.idDocumentNumber || touched.idDocumentNumber) &&
                    errors.idDocumentNumber
                    ? "err"
                    : ""
                    } item`}
                >
                  <div className={`form-item`}>
                    <input
                      type="text"
                      placeholder="请输入身份证号码"
                      onChange={(e) => {
                        const value = e.target.value.replace(/[\W]/g, '').toUpperCase();
                        setIdValue(value);
                        setFieldValue("idDocumentNumber", value);
                      }}
                      value={idValue}
                      maxLength={18}
                      value={values.idDocumentNumber}
                      onChange={(e) =>
                        setFieldValue(
                          "idDocumentNumber",
                          e.target.value?.toUpperCase()
                        )
                      }
                    />
                  </div>
                  {
                    (values.idDocumentNumber || touched.idDocumentNumber) &&
                      errors.idDocumentNumber ?
                      <div className="error">{errors.idDocumentNumber}</div>
                      :
                      <div>
                        {
                          !isOK && <div className="error">差价合约属高风险投资，不适合未成年人参与</div>
                        }
                      </div>
                  }

                </div>

                <div
                  className={`${(values.email || touched.email) && errors.email ? "err" : ""
                    } item`}
                >
                  <div className={`form-item email`}>
                    <input
                      type="text"
                      placeholder="请输入电子邮箱"
                      onChange={(e) => setFieldValue("email", e.target.value)}
                    />
                  </div>
                  {(values.email || touched.email) && errors.email && (
                    <div className="error">{errors.email}</div>
                  )}
                </div>
                <div className={styles.tips_message}>
                  {tips.msg && <p className={`${tips.type}`}>{tips.msg}</p>}
                </div>
                <div className="tools">
                  <button
                    type="submit"
                    disabled={isSubmiting}
                    className="block-show btn btn-register"
                  >
                    {isSubmiting ? "提交中..." : "领取50元红包"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {state.visible && (
        <div className={styles.popShow}>
          <div className={styles.success}>
            <h2>资料完善成功</h2>
            <p>
              <b>50元</b>红包已发放至您账户
            </p>
            <div className={styles.adv_word}>
              {isApp_key ? "激活最高可领取赠金" : "下载App领取"}
            </div>
            <div className={styles.btn_box}>
              <a className="auth" onClick={handleBtn}>
                {isApp_key ? "立即激活" : "立即下载"}
              </a>
              <a href={state.url}>{isApp_key ? "暂不激活" : "暂不下载"}</a>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
